<template>
  <div
    class="h-screen flex justify-center items-center px-2"
    v-if="isAccountPending"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 border-b-4 border-lula w-full md:w-2/3"
    >
      <h1 class="text-2xl text-gray-800 font-bold">
        Account pending coverage.
      </h1>
      <span class="font-bold text-gray-600">
        Coverage is not yet active for this account.
      </span>
      <br />
      <div className="bg-gray-100 border rounded-lg px-4 py-3 text-lg mt-2">
        <span className="uppercase font-bold text-lula"> notice </span>
        <br />
        <span class="text-gray-500">
          Do not cancel your current insurance coverage until 24 hours after
          your service effective date with GetAddify
        </span>
      </div>
      <div class="flex flex-wrap-reverse mt-2 sm:flex-nowrap">
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="$store.dispatch('signOut')"
        >
          Logout
        </button>
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="manageBilling"
        >
          Billing
        </button>
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="contactSupport"
        >
          Contact Support
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    return {
      contactSupport() {
        window.open("mailto: support@getaddify.com", "_blank");
      },
      async manageBilling() {
        try {
          store.commit("setLoading", true);
          const portal = await store.dispatch("getAccountStripePortal", {
            returnUrl: window.location.origin,
          });
          window.location.replace(portal);
        } catch (err) {
          store.commit("setLoading", false);
        }
      },
      isAccountPending: computed(() => {
        const account = store.state.account;
        return account != null && account.status === "Pending Activation";
      }),
    };
  },
};
</script>
